/*
* @author 友人a丶
* @date 2022-07-11
* 全局接口
* */


const url = new URL(window.location);
const BASE_URL = url.searchParams.get('api');
const BASE_KEY = url.searchParams.get('private');


export default {
    events: `${BASE_URL}/?nicen_replay_get_all_logs=1&private=${BASE_KEY}`,
    get: `${BASE_URL}/?nicen_replay_get_event=1&private=${BASE_KEY}`,
}