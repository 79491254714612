<template>
  <div class="main">
    <a-card title="演示">
      <template #extra>
        <a-space>
          <a-button @click="table.loadData" type="primary">查询</a-button>
          <v-pop/>
        </a-space>
      </template>

      <!-- 输出筛选条件 -->
      <v-batch label="fixed" :grid="{
          xxl: 8,
          xl: 8,
          lg: 8,
          md: 8,
          sm: 8,
          xs: 8
        }" layout="horizontal" :showReset="true" :form="form" :data="table.condition.data"/>

      <v-table :indentSize="20" :sticky="{offsetHeader: 0}" rowsKey="ip" :init="face">

        <template #summary>
          <a-table-summary fixed="bottom">
            <a-table-summary-row>

              <a-table-summary-cell>
                <span style="white-space: nowrap;color: red;">总计</span>
              </a-table-summary-cell>

              <a-table-summary-cell :colspan="10">
                <span style="white-space: nowrap;color: red;">
                当日数据：UV {{ summary.count }}，PV {{ summary.total }}
                </span>
              </a-table-summary-cell>

            </a-table-summary-row>
          </a-table-summary>
        </template>

        <template #bodyCell="{column,index,record }">

          <template v-if="column.title === 'ID'">
            {{ index + 1 }}
          </template>

          <template v-else-if="column.title === '操作'">
            <a @click="loadEvent(record.file)">播放</a>
          </template>

        </template>
      </v-table>

    </a-card>
  </div>


  <div class="modal" v-show="visible">
    <v-icon @click="close" size="36" class="close" icon="CloseOutlined"></v-icon>
    <div class="container">
      <div class="player" ref="player"></div>
    </div>
  </div>

</template>
<script setup>
/* eslint-disable */
import initTable from '@/common/init-table'
import {onMounted, reactive, ref} from "vue";
import api from "@/config/api";
import load from "@/common/load";
import axios from "axios";
import rrwebPlayer from "rrweb-player";
import 'rrweb-player/dist/style.css';
import {switchForm} from "@/common";


/* 接口 */
const face = api.events

/* 表单 */
const form = [
  {
    key: 'date',
    type: "date-picker",
    label: "日期",
    attr: {
      placeholder: "选择日期"
    }
  },
  {
    key: 'ip',
    type: "input",
    label: "指定IP",
    attr: {
      placeholder: "输入指定IP"
    }
  }
];


const player = ref(null);

/* 统计 */
const summary = reactive({
  count: 0,
  total: 0
});


/* 表格列配置 */
const columns = [
  {
    "title": "IP",
    "dataIndex": "ip",
    "width": 150
  },
  {
    "title": "IP地址",
    "dataIndex": "city",
    "width": 150
  },
  {
    "title": "时间",
    "dataIndex": "time",
    "timestamp": 'YYYY-MM-DD HH:mm:ss',
    "width": 150
  },
  {
    "title": "访问次数",
    "dataIndex": "count",
    "width": 80
  },
  {
    "title": "日志大小",
    "dataIndex": "size",
    "width": 150
  },
  {
    "title": '操作',
    "width": 100,
    "minWidth": 100,
    "fixed": "right"
  }
];


const table = initTable({
  unique: 'rrweb-list-Table',
  column: columns,
  immediate: false,
  filter: (data) => {

    summary.total = data.length;

    const json = data.sort((a, b) => {
      return b.time - a.time;
    })

    const filter = Object.create(null);

    json.forEach(item => {
      if (!filter[item.ip]) {
        filter[item.ip] = item;
        filter[item.ip].children = [];
        filter[item.ip].count = 1;
      } else {
        filter[item.ip].children.push(item);
        filter[item.ip].count++;
      }
    })


    const rows = Object.values(filter);
    summary.count = rows.length;

    rows.forEach(item => {
      item.children = item.children.sort((a, b) => {
        return b.time - a.time;
      })
    })

    return {
      data: rows
    }
  },
  condition: {
    date: null,
    ip: null
  }
});


/**
 * 加载数据
 */
onMounted(() => {
  table.loadData();
})


const visible = ref(false);
let playing = null;

/* 关闭 */
const close = () => {
  playing.pause();
  visible.value = false;
}

/**
 * 播放
 */
const play = (data) => {
  visible.value = true;
  player.value.innerHTML = "";
  playing = new rrwebPlayer({
    target: player.value, // customizable root element
    props: {
      events: data
    },
  });
}


/**
 * 加载事件
 */
const loadEvent = (file) => {

  /* 延时 */
  load.loading("加载中...");

  try {

    /* 开始请求 */
    axios.post(api.get, switchForm({
      date: table.condition.data.date,
      file: file
    }))
        .then((res) => {
          if (res.data.code) {


            /* 数据 */
            const data = [];

            /* 合并数据 */
            res.data.data.forEach(item => {
              if (item) {
                item.forEach(event => {
                  data.push(event);
                });
              }
            })

            /* 播放数据 */
            play(data);

            load.success(res.data.errMsg);

          } else {
            load.error(res.data.errMsg);
          }

          load.loaded();
        }).catch((e) => {
      load.error(e.message);
    })


  } catch (e) {
    console.log(e)
    load.error(e);
  }

}

</script>

<style scoped lang="scss">


.main {
  max-width: 1200px;
  margin: 30px auto;
  @include full;

  .apply {
    @include flex-center;
    justify-content: center;
    width: 100%;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(#000000, 0.3);

  .close {
    position: absolute;
    right: 50px;
    top: 50px;
    font-size: 24px;
    color: white;
  }
}
</style>